import { MetaSEO } from "src/components";
import { H1 } from "src/elements/Typography";

function Home() {
  return (
    <div className="Home">
      <MetaSEO title="Homepage" description="Blog's homepage" />
      <H1>Home</H1>
    </div>
  );
}

export default Home;
